import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';

import { reducer as alert } from './alert';
import { reducer as auth } from './auth';
import { reducer as users } from './user';
import { reducer as search } from './search';

export default combineReducers({
    alert,
    auth,
    search,
    settings: settingsReducer,
    theme: themesReducer,
    users
});
