import { AUTH } from '../constants';
import { AlertActions } from './';
import { history } from '../../helpers';
import { AuthService } from '../../services';

import { CognitoUser } from '@aws-amplify/auth';

export interface SignUpRequest {
  name: string;
  email: string;
  password: string;
  phone_number: string;
  zip_code: string;
  state: string;
  company: string;
}


export class AuthActions {
  static login(username: string, password: string, redirectUrl?: string) {

    function request(user: any) { return { type: AUTH.LOGIN_REQUEST, user } }
    function success(user: CognitoUser) { return { type: AUTH.LOGIN_SUCCESS, user } }
    function failure(error: any) { return { type: AUTH.LOGIN_FAILURE, error } }

    return (dispatch: Function) => {
      dispatch(request({ username }));

      AuthService
        .login(username, password)
        .then(user => {
          dispatch(AlertActions.clear());
          dispatch(success(user));
          history.push(redirectUrl || '/');
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        })
    }
  }

  static forcePasswordReset(name: string, password: string) {

    function request(meta: any) { return { type: AUTH.FORCE_CHANGE_PASSWORD_REQUEST, meta } }
    function success() { return { type: AUTH.FORCE_CHANGE_PASSWORD_SUCCESS } }
    function failure(error: any) { return { type: AUTH.FORCE_CHANGE_PASSWORD_FAILURE, error } }

    return (dispatch: Function) => {
      dispatch(request({ name }));

      AuthService
        .completeNewPassword(AuthService.challengeUser, { name, password })
        .then(user => {
          dispatch(AlertActions.clear());
          dispatch(success());
          history.push('/');
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        });
    };
  }

  static signUp(opts: SignUpRequest) {
    const { name, email, phone_number, password, zip_code, state, company } = opts;
    function request(user: any) { return { type: AUTH.SIGNUP_REQUEST, user } }
    function successWithConfirmation (user: CognitoUser) { return { type: AUTH.SIGNUP_SUCCESS_NEEDS_CONFIRMATION , user} }
    function success(user: CognitoUser) { return { type: AUTH.SIGNUP_SUCCESS, user } }
    function failure(error: any) { return { type: AUTH.SIGNUP_FAILURE, error } }

    return (dispatch: Function) => {
      dispatch(request({ name, email, phone_number, zip_code }));

      AuthService
        .signUp(email, password, { phone_number, name, email, zip_code, company, state })
        .then(signupResult => {
          dispatch(AlertActions.clear());
          if (signupResult.userConfirmed) {
            dispatch(success(signupResult.user));
            history.push('/');
          } else {
            dispatch(successWithConfirmation(signupResult.user));
          }
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        })
    }
  }

  static confirmSignUpCode(username: string, code: string) {
    function request(username: string) { return { type: AUTH.CODE_REQUEST, username } }
    function success() { return { type: AUTH.CONFIRM_CODE_SUCCESS } }
    function failure(error: any) { return { type: AUTH.CONFIRM_CODE_FAILURE, error } }

    return (dispatch: Function) => {
      dispatch(request(username));

      AuthService
        .confirmSignUp(username, code)
        .then(() => {
          dispatch(AlertActions.clear());
          dispatch(success());
          history.push('/login');
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        });
    };
  }

  static resendAuthCode(username: string) {
    function request(username: string) { return { type: AUTH.RESEND_CODE_REQUEST, username } }
    function success() { return { type: AUTH.RESEND_CODE_SUCCESS } }
    function failure(error: string) { return { type: AUTH.RESEND_CODE_FAILURE, error } }
    return (dispatch: Function) => {
      dispatch(request(username));

      AuthService.resendSignUpToken(username)
        .then(() => {
          dispatch(AlertActions.clear());
          dispatch(success());
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        })
    }
  }

  static changePassword(old: string, newPassword: string) {
    function request() { return { type: AUTH.CHANGE_PASSWORD_REQUEST } }
    function success() { return { type: AUTH.CHANGE_PASSWORD_SUCCESS } }
    function failure(error: string) { return { type: AUTH.CHANGE_PASSWORD_FAILURE, error } }

    return (dispatch: Function) => {
      dispatch(request());

      AuthService.changePassword(old, newPassword)
        .then(() => {
          dispatch(success());
          dispatch(AlertActions.success('Successfully changed password'));
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message))
        })
    };
  }

  static logout() {
    // AuthService.logout();
    return {
      type: AUTH.LOGOUT
    }
  }
}
