import { history } from '../helpers';
import AppConfig from '../config';
import Auth from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import { CustomCognitoAttrs } from '../interfaces';
import store from '../store/store';
import { AuthActions, SearchActions, UserActions } from '../store/actions';

export const USER_DATA = 'user';
export const TOKEN_NAME = 'jwt_token';

type signUpParams = 'email' | 'phone_number' | 'name' | 'state' | 'company';

export class AuthService {
  private static _challengeUser: CognitoUser;

  static customAttrs = ['company', 'state', 'zip_code'];

  static get challengeUser() {
    return AuthService._challengeUser;
  }

  static set challengeUser(user: CognitoUser) {
    AuthService._challengeUser = user;
  }

  static clearChallengeUser(): void {
    // @ts-ignore
    AuthService._challengeUser = null;
  }

  public static getToken(): string {
    const clientID = AppConfig.AWS_AMPLIFY_CLIENTID;
    const email = localStorage.getItem(`CognitoIdentityServiceProvider.${clientID}.LastAuthUser`);
    return localStorage.getItem(`CognitoIdentityServiceProvider.${clientID}.${email}.idToken`) || '';
  }

  private static getTokenExpirationDate(token: string): Date | null {
    try {
      const decoded = jwt_decode(token) as any as { exp: number };

      if (!decoded.exp) {
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);

      return date;
    } catch (e) {
      return null;
    }
  }

  private static tokenNotExpired(): boolean {
    const token = AuthService.getToken();

    if (!token) {
      return false;
    }

    const token_date = AuthService.getTokenExpirationDate(token);

    if (!token_date) {
      return false;
    }

    return token_date.valueOf() > new Date().valueOf();
  }

  private static setUser(user: string): void {
    localStorage.setItem(USER_DATA, user);
  }

  static isAdmin(): boolean {
    try {
      return !!JSON.parse(localStorage.getItem(USER_DATA) || '').is_admin;
    } catch (error) {
     return false; 
    }
    
  }

  static async login(username: string, password: string): Promise<CognitoUser> {

    const user: CognitoUser = await Auth.signIn(username, password);

    // @ts-ignore
    if (user.challengeName) {
       AuthService.challengeUser = user;
       history.push('/password-reset');
       // @ts-ignore
       throw new Error(user.challengeName);
     }

     AuthService.handleAWSAuthSuccess(user);
     return user;
  }

  static formatCustomAttrs(attrs: {[key: string]: string}): Record<string, string> {
    const formatted: Record<string, string> = {};
    Object.keys(attrs).forEach(raw => {
      const key = AuthService.customAttrs.includes(raw) ?  `custom:${raw}` : raw;
      formatted[key] = attrs[raw];
    });
    return {
      ...formatted,
      phone_number: (formatted.phone_number || '').replace(/(-|\(|\))/g, '')
    };
  }


  static async signUp(username: string, password: string, attrs: CustomCognitoAttrs) {
    return Auth.signUp({
      username,
      password,
      attributes: AuthService.formatCustomAttrs(attrs as any as {[key: string]: string}),
      validationData: []  //optional
      });
  }

  static async confirmSignUp(username: string, code: string) {
    return Auth.confirmSignUp(username, code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true    
        });
  }

  static async changePassword(old: string, newPassword: string) {
    return Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, old, newPassword);
    })
  }

  static async resendSignUpToken(username: string) {
    return Auth.resendSignUp(username);
  }

  static async completeNewPassword(user: CognitoUser,
      { password, name }: { password: string; name: string }) {
        const passRes = await Auth.completeNewPassword(user, password, {name})

        AuthService.handleAWSAuthSuccess(passRes);
        return Promise.resolve();
  }

  private static handleAWSAuthSuccess(response: CognitoUser | any) {
    // @ts-ignore
    const { signInUserSession: { idToken } } = response;
    AuthService.setUser(JSON.stringify({
      name: idToken.payload.name,
      is_admin: (idToken.payload['cognito:groups'] || []).map((t: string) => t.toLowerCase()).includes('admin'),
      phone_number: idToken.payload.phone_number,
      email: idToken.payload.email }));
  }

  static logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    store.dispatch(AuthActions.logout());
    store.dispatch(SearchActions.clear());
    store.dispatch(UserActions.clearUser());
  }
}
