import { RouteComponentProps } from "react-router";
import { DeliveryStatus, DeliveryProcessStatus } from '@betaquick/casepipeline-constants';
export * from './user';
export * from './app-state';
export * from './Menu';
export * from './cognito';
export * from './save-filters';

export interface PagePropsWithDispatch extends Partial<RouteComponentProps> {
  dispatch: Function;
}

export interface CourtData {
  court_id: number; state: string; name: string
}

export interface FilterExecutionDetail {
  executionDate: Date;
  executionId: number;
  resultSize: number
};

export interface FilterDetail {
  site: string;
  customerType: string;
  caseStatus: string;
  filterName: string;
  courts: Array<{
    name: string;
    state: string;
  }>;
  caseTypes: string | string[]
}

export interface MailTemplate {
  templateId: number;
  templateName: string;
  previewURL: string;
  color: boolean;
  templateType: string // use enum of MAIL_TEMPLATE_TYPES
}

export interface Delivery {
  deliveryId: number;
  createdAt: string;
  processedAt?: string;
  status: DeliveryStatus; totalCost: number; recipientCount: number;
  templateName: string;
  previewURL: string; color: boolean; templateType: string; price: string;
}

export interface DeliveryWithMailDetails extends Delivery {
  // TODO (oneeyedsunday) reduce payload, only show name & address details
  mails: {
    case_resultId: number;street: string;city: string;state: string;
    zip: string;court_name: string;filing_date: string;
    case_description: string; case_numbers: string; case_caption: string;
    name: string; process_status: DeliveryProcessStatus
  }[]
}
