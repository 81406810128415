import { lazy, LazyExoticComponent, ComponentType } from 'react';

export * from './Login';
export * from './PasswordReset';
export * from './SignUp';
export * from './Profile';

type keys = 'Signup' | 'Login' | 'PasswordReset' | 'ProfilePage';

export const lazyContainers: Record<keys, LazyExoticComponent<ComponentType<any>>> = {
    'Signup': lazy(() => import('./SignUp')),
    'Login': lazy(() => import('./Login')),
    'PasswordReset': lazy(() => import('./PasswordReset')),
    'ProfilePage': lazy(() => import('./Profile'))
}

export default lazyContainers;
