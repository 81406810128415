import { AUTH } from '../constants';
import { AuthState as State } from '../../interfaces';

let user = JSON.parse(localStorage.getItem('user') as string);
const initialState: State = {
  loggedIn: !!user,
  loggingIn: false,
  needsCode: false,
  signingUp: false,
  changingPassword: false,
  user: user
}

export function reducer(state = initialState, action: { type: AUTH, user: any }): State {
    switch (action.type) {
    case AUTH.LOGIN_REQUEST:
        return {
          ...state,
        loggingIn: true,
        user: action.user
        };
    case AUTH.SIGNUP_REQUEST:
        return {
          ...state,
          signingUp: true
        };
    case AUTH.LOGIN_SUCCESS:
        return {
          ...state,
          loggedIn: true,
          loggingIn: false,
          user: action.user
        };
    case AUTH.SIGNUP_SUCCESS:
        return {
          ...state,
          signingUp: false
        };
    case AUTH.SIGNUP_SUCCESS_NEEDS_CONFIRMATION:
        return {
          ...state,
          needsCode: true,
          signingUp: false
        };
    case AUTH.LOGIN_FAILURE:
        return {
          ...state,
          loggingIn: false
        } as State;
    case AUTH.SIGNUP_FAILURE:
        return {
          ...state,
          signingUp: false
        };
    case AUTH.LOGOUT:
        return {
          ...state,
          changingPassword: false,
          needsCode: false,
          user: null,
          loggedIn: false
        } as State;
    case AUTH.FORCE_CHANGE_PASSWORD_REQUEST: case AUTH.CODE_REQUEST:
        return state;
    case AUTH.FORCE_CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          loggingIn: false,
          loggedIn: true
        };
    case AUTH.CONFIRM_CODE_SUCCESS: case AUTH.CONFIRM_CODE_FAILURE:
        return {
          ...state,
          needsCode: false
        }
    case AUTH.FORCE_CHANGE_PASSWORD_FAILURE:
        return {
          ...state,
          loggingIn: false,
          loggedIn: false
        };
    case AUTH.CHANGE_PASSWORD_REQUEST:
        return {
          ...state,
          changingPassword: true
        };
    case AUTH.CHANGE_PASSWORD_SUCCESS: case AUTH.CHANGE_PASSWORD_FAILURE:
        return {
          ...state,
          changingPassword: false
        };
    default:
        return state
    }
}
