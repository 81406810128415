import { Auth } from 'aws-amplify';

export async function authHeader(): Promise<{[key: string]: string }> {
    let token = '';
    try {
        const currentSession = await Auth.currentSession();
        token = currentSession.getIdToken().getJwtToken();
    } catch (error) {
    }
    return {
            'Content-Type': 'application/json',
            'Authorization': token
        }
}
