import { SEARCH } from '../constants';
import { SearchOptions, SearchMeta } from '../reducers/search';

export class SearchActions {
  static clear() {
    return {
      type: SEARCH.CLEAR_SEARCH
    }
  }

  static initiate(params: SearchOptions, meta: SearchMeta) {
    return {
      type: SEARCH.INITIATE_SEARCH,
      params, meta
    }
  }

  static clearMeta() {
    return {
      type: SEARCH.CLEAR_META
    }
  }
}
