import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function isAdmin(userAsString: string | null) {
    try {
        return JSON.parse(userAsString || '').is_admin;
    } catch (error) {
        return false;
    }
}

const AdminRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={props => (
       isAdmin(localStorage.getItem('user'))
        ? <Component {...props} />
        : <Redirect to={{ pathname: "/login", state: { from: props.location, redirectTo: '/' } }} />
    )} />
);

export default AdminRoute;
