import { ALERT } from '../constants';

export class AlertActions {
    static success(message: string) {
      return {
        type: ALERT.SUCCESS,
        message
      }
    }

    static error(message: string) {
      return {
        type: ALERT.ERROR,
        message
      }
    }

    static clear() {
      return {
        type: ALERT.CLEAR
      }
    }
}