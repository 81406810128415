export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    adminOnly?: boolean;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

export const Menu: ISidebarMenu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '',
        icon: 'icon-home',
        translate: 'sidebar.nav.WELCOME'
    },
    {
        name: 'Admin',
        adminOnly: true,
        icon: 'icon-settings',
        submenu: [
            { name: 'Mails', path: '/admin/mail', icon: 'icon-envelope' }
        ]
    },
    {
        name: 'Profile',
        path: '/profile',
        icon: 'icon-user'
    }
];

