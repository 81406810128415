import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import AppConfig from './config';
import Amplify from '@aws-amplify/auth';

import './i18n';

import store from './store/store';

const amplifyConfig = {
    Auth: {
  
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: AppConfig.AWS_USERPOOLID,
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: AppConfig.AWS_AMPLIFY_CLIENTID,
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
  
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
};
  
Amplify.configure(amplifyConfig);


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);
