import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import { BlankPage } from './components/Layout';

import { PrivateRoute, AdminRoute } from './components';
import lazyLoadedContainers from './containers';

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props}/>;

const Search = lazy(() => import('./containers/Search/Search'));
const Results = lazy(() => import('./containers/Search/Results'));
const FilterDetails = lazy(() => import('./containers/Filters/Details'));
const MailDeliver = lazy(() => import('./containers/Mail/TemplateSelection'));
const AdminRoutes = lazy(() => import('./containers/Admin'));



const Routes = ({ location }: RouteProps ) => {
    const currentKey = location!.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn';

    const BlankLayout = ({ component: Component, ...rest }: any) => (
        <BlankPage>
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />
        </BlankPage>
      );

    const AppLayout = (
        // Layout component wrapper
        // Use <BaseHorizontal> to change layout
        <Base>
            <TransitionGroup>
            <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                <div>
                    <Suspense fallback={<PageLoader/>}>
                        <Switch location={location}>
                            <PrivateRoute path="/profile" component={waitFor(lazyLoadedContainers.ProfilePage)} />
                            <PrivateRoute path="/results" component={waitFor(Results)} />
                            <PrivateRoute path="/executeFilter/:filterId" component={waitFor(Results)}/>
                            <PrivateRoute path="/casefilter/:filterId" component={waitFor(FilterDetails)} />
                            <PrivateRoute path="/deliver" component={waitFor(MailDeliver)} />
                            <AdminRoute path="/admin" component={waitFor(AdminRoutes)} />
                            <PrivateRoute path="/" exact component={waitFor(Search)} />
                            <Redirect to="/"/>
                        </Switch>
                    </Suspense>
                </div>
            </CSSTransition>
            </TransitionGroup>
        </Base>
    )


    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch location={location}>
                <BlankLayout path="/signup" component={waitFor(lazyLoadedContainers.Signup)} />
                <BlankLayout path="/login" component={waitFor(lazyLoadedContainers.Login)} />
                <BlankLayout path="/password-reset" component={waitFor(lazyLoadedContainers.PasswordReset)} />
                <Route>
                    { AppLayout }
                </Route>
            </Switch>
        </Suspense>
    );
}

export default withRouter(Routes);
