import React , { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AuthActions, AlertActions } from '../store/actions';
import { PagePropsWithDispatch, AppState } from '../interfaces';
import { AuthService } from '../services';
import { LoadingSpinner } from '../components/Casepipeline';
import { isEmpty } from '../helpers';

interface PageProps extends PagePropsWithDispatch {
  loggingIn: boolean;
}


const PasswordResetPage = (props: PageProps) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { dispatch, loggingIn } = props;

  useEffect(() => {
    checkForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkForUser(): void {
    if (!AuthService.challengeUser) {
      dispatch(AlertActions.error('No Auth User, please log in first'));
      props.history!.push('/login');
    }
  }


  function handleChange(e: any) {
      const { name, value } = (e.target) as any as HTMLInputElement;

      switch (name) {
        case 'name':
          setName(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'confirmPassword':
          setConfirmPassword(value);
          break;
        default:
          return
      }
  }

  function handleSubmit(e: any) {
      e.preventDefault();

      setSubmitted(true);
      if (name && password && confirmPassword && (confirmPassword === password)) {
          dispatch(AuthActions.forcePasswordReset(name, password));
      }
  }

  function hasError(): boolean {
    return (password !== confirmPassword) || [name, password, confirmPassword].some(isEmpty);
  }

  function passwordMatch(): boolean {
    return (password === confirmPassword);
  }

  return (
    <div>
         <p className="text-center py-2 text-uppercase">Password Reset</p>
         <form name="form" onSubmit={handleSubmit}>
             <div className={'form-group' + (submitted && hasError() ? ' has-error' : '')}>
                 <label htmlFor="name">Name</label>
                 <input type="text" className="form-control" name="name" value={name} onChange={handleChange} />
                 {submitted && isEmpty(name) &&
                     <div className="help-block">Name is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(password) ? ' has-error' : '')}>
                 <label htmlFor="password">Password</label>
                 <input type="password" className="form-control" name="password" value={password} onChange={handleChange} />
                 {submitted && isEmpty(password) &&
                     <div className="help-block">Password is required</div>
                 }
                 {submitted && !passwordMatch() &&
                     <div className="help-block">Passwords must match</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(confirmPassword) ? ' has-error' : '')}>
                 <label htmlFor="confirmPassword">Confirm Password</label>
                 <input type="password" className="form-control" name="confirmPassword" value={confirmPassword} onChange={handleChange} />
                 {submitted && isEmpty(confirmPassword) &&
                     <div className="help-block">Password is required</div>
                 }
                 {submitted && !passwordMatch() &&
                     <div className="help-block">Passwords must match</div>
                 }
             </div>
             <div className="form-group d-flex pt-3">
                 <button className="btn btn-primary btn-block"
                 disabled={loggingIn}
                 >Reset Password</button>
                 {loggingIn &&  <LoadingSpinner /> }
             </div>
         </form>
    </div>
  );
};


function mapStateToProps(state: AppState) {
  const { loggingIn } = state.auth;
  return {
    loggingIn
  };
}

const connectedPage = connect(mapStateToProps)(PasswordResetPage);
export { connectedPage as PasswordReset };
export default connectedPage;
