/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './helpers';
import { PagePropsWithDispatch, AppState, AlertState } from './interfaces';
import { AlertActions } from './store/actions';
import { Alert } from 'reactstrap';
import { ResponsiveWrapperNoBG } from './components/Casepipeline/wrappers';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
import 'react-virtualized/styles.css';

declare var PUBLIC_URL: string;

interface AlertSectionProps extends PagePropsWithDispatch {
  alert: AlertState
}

const AppAlerts = (props: AlertSectionProps): JSX.Element => {
  const { alert, dispatch } = props;
  let alertRef = useRef<HTMLDivElement>(null);

  const [alertVisible, setAlertVisible] = useState<boolean>(!!alert.message);

  function setClearBtnStyle() {
    const ClearBtn = alertRef.current!.querySelector<HTMLButtonElement>('button.close');

    if (ClearBtn) {
      ClearBtn!.style.fontSize = '1.2em';
    }
  }

  useEffect(() => {
    if (alertRef.current) {
      setClearBtnStyle();
      if (!['/login', '/signup', '/password-reset'].includes(history.location.pathname)) {
        alertRef.current!.classList.remove('mt-2');
        alertRef.current!.style.marginTop = '5em';
      } else {
        alertRef.current!.classList.add('mt-2');
        alertRef.current!.style.marginTop = null;
      }
    }

    if (alert.message) {
      setAlertVisible(true);
      setClearBtnStyle();
    }
    
    history.listen(evt => {
      if (!(evt.state || { keepAlerts: false }).keepAlerts) {
        dispatch(AlertActions.clear());
      }
    });
  }, [alert, dispatch]);


  function dismissAlert() {
    setAlertVisible(curr => !curr);
    props.dispatch(AlertActions.clear());
  }


  return (
    <>
      {
        alert.message &&
            <div id="app-alert-bar"
            ref={alertRef} className="mt-2 block-center wd-xl d-flex justify-content-center flex-column">
              <ResponsiveWrapperNoBG>
                <Alert color={alert.type.replace('alert-','')} isOpen={alertVisible}
                className={"mb-0 text-center".concat(alertVisible ? '' : ' d-none')}
                toggle={dismissAlert}>{alert.message}</Alert>
              </ResponsiveWrapperNoBG>
            </div>
      }
    </>
  );
};

function mapStateToProps(state: AppState) {
  return {
    alert: state.alert
  };
}

const AppAlertsConnected = connect(mapStateToProps)(AppAlerts);


const App = () => {
  // specify base href from env varible 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only
  return (
      <>
       <AppAlertsConnected />
        <Router history={history}>
            <Routes />
        </Router>
      </>
  );
};


export default App;
