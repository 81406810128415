import { USER } from '../constants';
import { UserService } from '../../services';
import { AlertActions } from '../actions';
import { PrunnedProfileOpts } from '../../interfaces';
import store from '../store';



export class UserActions {

  static getUser() {
    function request(){ return  { type: USER.GET_USER_REQUEST }}
    function success(userAttributes: any) { return { type: USER.GET_USER_SUCCESS, userAttributes } }
    function failure(error: string) { return { type: USER.GET_USER_FAILURE, error } }
    return (dispatch: Function) => {
      dispatch(request());
      UserService.getCurrentUser()
        .then(res => {
          dispatch(success(res.attributes));
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        });
    }
  }

  static updateBio(opts: PrunnedProfileOpts) {
    function request(meta: any) { return { type: USER.UPDATE_USER_BIO_REQUEST, meta } }
    function success() { return { type: USER.UPDATE_USER_BIO_SUCCESS } }
    function failure(error: string) { return { type: USER.UPDATE_USER_BIO_FAILURE, error } }

    const { getState } = store;
    const { attributes } = getState().users;

    return (dispatch: Function) => {
      dispatch(request(opts));

      UserService.updateBio({ ...opts, phone_number: (attributes || {}).phone_number })
        .then(res => {
          dispatch(success());
          dispatch(AlertActions.success('Updated your bio successfully'));
        })
        .catch((error: Error) => {
          dispatch(failure(error.message));
          dispatch(AlertActions.error(error.message));
        });
    };
  }

  static clearUser() {
    return {
      type: USER.CLEAR_USER
    }
  }
}
