export enum USER {
  GETALL_REQUEST = 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS = 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE = 'USERS_GETALL_FAILURE',

  GET_USER_REQUEST = 'USER_GET_REQUEST',
  GET_USER_SUCCESS = 'USER_GET_SUCCESS',
  GET_USER_FAILURE = 'USER_GET_FAILURE',

  UPDATE_USER_BIO_REQUEST = 'USER_USER_BIO_REQUEST',
  UPDATE_USER_BIO_SUCCESS = 'USER_USER_BIO_SUCCESS',
  UPDATE_USER_BIO_FAILURE = 'USER_USER_BIO_FAILURE',

  CLEAR_USER = 'USER_CLEAR'
}
