import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { PagePropsWithDispatch, AppState } from '../interfaces';
import ContentWrapper from '../components/Layout/ContentWrapper';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { STATES as states } from '@betaquick/casepipeline-constants';
import { AuthActions, UserActions } from '../store/actions';
import { FormLabel, ThemedCardHeader, LoadingSpinner } from '../components/Casepipeline';
import { isEmpty } from '../helpers';

interface PageProps extends PagePropsWithDispatch {    
  changingPassword: boolean;
  alertType: string;
  alertMessage: string | undefined;
  updatingBio: boolean;
  attributes: any;
}


const STATES: Array<{value: string; text: string}> = states.map(({name, abbr}) => ({ text: name, value: abbr }));

const Profile = (props: PageProps) => {
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [zip_code, setZip_code] = useState('');
  const [company, setCompany] = useState('');
  const [state, setState] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);

  const { changingPassword, alertType, updatingBio, dispatch, attributes, alertMessage } = props;

  useEffect(() => {
    dispatch(UserActions.getUser());
  }, [dispatch]);

  useEffect(() => {
    if (attributes && Object.keys(attributes).length) {
      setName(attributes.name);
      setState(attributes['custom:state']);
      setCompany(attributes['custom:company']);
      setZip_code(attributes['custom:zip_code']);
    }
  }, [attributes]);


  useEffect(() => {
    if (alertType === 'alert-success') {
      setConfirmPassword('');
      setPassword('');
      setOldPassword('');
      setPasswordSubmitted(false);
    }
  }, [alertType, alertMessage]);

  function handleChange(e: any) {
    const { name, value } = (e.target) as any as HTMLInputElement;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      case 'zip_code':
        setZip_code(value);
        break;
      case 'company':
        setCompany(value);
        break;
      case 'state':
        setState(value);
        break;
      default:
        return
    }
  }

  function hasAllRequiredAttrs(): boolean {
    return [name, state, company, zip_code].every(item => (!isEmpty(item)));
  }

  function passwordMatch(): boolean {
    return (password === confirmPassword);
  }

  function handleProfileUpdate(e: any) {
      e.preventDefault();

      setSubmitted(true);

      const { dispatch } = props;
      if (hasAllRequiredAttrs()) {
          dispatch(UserActions.updateBio({ state, zip_code, name, company }));
      }
  }

  function handlePasswordUpdate(e: any) {
    e.preventDefault();

    setPasswordSubmitted(true);

    const { dispatch } = props;

    if (oldPassword && password && passwordMatch()) {
      dispatch(AuthActions.changePassword(oldPassword, password));
    }
  }

  return (
    <ContentWrapper>
        <Row>
            <Col md={8} className="offset-md-2">
                <Card className="mb-3">
                  <ThemedCardHeader>Manage Password</ThemedCardHeader>
                  <CardBody>
                    <form className="form-horizontal" onSubmit={handlePasswordUpdate}>
                        <div className={"form-group row" + (passwordSubmitted && isEmpty(oldPassword) ? ' has-error' : '') }>
                          <FormLabel {...{ required: true }}>Current Password</FormLabel>
                          <div className="col-xl-9">
                            <input className="form-control" type="password" required 
                            placeholder="Current Password" name="oldPassword"
                            value={oldPassword} onChange={handleChange} />
                              {passwordSubmitted && isEmpty(oldPassword) &&
                                  <div className="help-block">Current Password is required</div>
                              }
                          </div>
                        </div>
                        <div className={"form-group row" + (passwordSubmitted && isEmpty(password) ? ' has-error' : '') }>
                          <FormLabel {...{ required: true }}>New Password</FormLabel>
                          <div className="col-xl-9">
                            <input className="form-control" type="password" required 
                            placeholder="New Password" name="password"
                            value={password} onChange={handleChange} />
                              {passwordSubmitted && isEmpty(password) &&
                                  <div className="help-block">Password is required</div>
                              }
                              {passwordSubmitted && !passwordMatch() &&
                                  <div className="help-block">Passwords must match</div>
                              }
                          </div>
                        </div>
                        <div className="form-group row">
                          <FormLabel {...{ required: true }}>Confirm New Password</FormLabel>
                            <div className="col-xl-9">
                              <input className="form-control" type="password" required 
                              placeholder="Confirm New Password" name="confirmPassword"
                              value={confirmPassword} onChange={handleChange} />

                                {passwordSubmitted && isEmpty(confirmPassword) &&
                                    <div className="help-block">Confirm Password is required</div>
                                }
                                {passwordSubmitted && !passwordMatch() &&
                                    <div className="help-block">Passwords must match</div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-xl-9 offset-xl-3">
                              <button className="btn btn-sm btn-success" 
                              type="submit"
                              disabled={changingPassword || isEmpty(oldPassword) || !(password && passwordMatch())}>
                                Update
                                {  changingPassword && <LoadingSpinner /> }
                              </button>
                            </div>
                        </div>
                    </form>

                  </CardBody>
                </Card>
            </Col>
            <Col md={8} className="offset-md-2">
              <Card className="mb-3">
                <ThemedCardHeader>Manage Bio</ThemedCardHeader>
                <CardBody>
                  <form className="form-horizontal" onSubmit={handleProfileUpdate}>
                      <div className={'form-group row' + (submitted && isEmpty(name) ? ' has-error' : '')}>
                      <FormLabel {...{required: true}}>Full Name</FormLabel>
                        <div className="col-xl-9">
                          <input type="text" className="form-control" required 
                          name="name" value={name} onChange={handleChange} />
                          {submitted && isEmpty(name) &&
                              <div className="help-block">Name is required</div>
                          }
                        </div>
                      </div>
                      <div className={"form-group row " + (submitted && isEmpty(company) ? 'has-error' : '')}>
                        <FormLabel {...{required: true}}>Company</FormLabel>
                          <div className="col-xl-9">
                            <input className="form-control" required name="company"
                            value={company} onChange={handleChange} />
                            {
                              submitted && isEmpty(company) && 
                                <div className="help-block">Company Name is required</div>
                            }
                            </div>
                      </div>
                      <div className={"form-group row " + (submitted && isEmpty(state) ? 'has-error' : '') }>
                        <FormLabel {...{required: true}}>State</FormLabel>
                          <div className="col-xl-9">
                          <select className="custom-select custom-select-sm" 
                          name="state" onChange={handleChange} value={state}>
                              <option {...{disabled: state ? true : undefined}} value="">--Please select a state--</option>
                              {
                                STATES.map(({ text, value }, index) => <option value={value} key={index}>{text}</option>)
                              }
                          </select>
                          {
                            submitted && isEmpty(state) && 
                              <div className="help-block">State is required</div>
                          }
                          </div>
                      </div>
                      <div className={"form-group row " + (submitted && isEmpty(zip_code) ? 'has-error' : '' )}>
                        <FormLabel {...{required: true}}>Zip</FormLabel>
                          <div className="col-xl-9">
                            <input className="form-control" required name="zip_code"
                            value={zip_code} onChange={handleChange} />
                            {
                              submitted && isEmpty(zip_code) && 
                                <div className="help-block">Zip Code is required</div>
                            }
                          </div>
                      </div>
                     
                      <div className="form-group row">
                          <div className="col-xl-9 offset-xl-3">
                            <button className="btn btn-sm btn-success" 
                            disabled={(updatingBio || !hasAllRequiredAttrs())}
                            type="submit">
                              Update
                              { updatingBio && <LoadingSpinner /> }
                            </button>
                          </div>
                      </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
        </Row>
    </ContentWrapper>
  );
}

function mapStateToProps(state: AppState) {
  const { changingPassword } = state.auth;
  const { type, message } = state.alert;
  const { updatingBio, attributes } = state.users;
  return {
    changingPassword,
    alertType: type,
    alertMessage: message,
    updatingBio,
    attributes
  }
}

const connectedPage = connect(mapStateToProps)(Profile);
export { connectedPage as ProfilePage };
export default connectedPage;
