export enum AUTH {
  LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE',

  SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS',
  SIGNUP_SUCCESS_NEEDS_CONFIRMATION = 'AUTH_SIGNUP_SUCCESS_NEEDS_CONFIRMATION',
  SIGNUP_FAILURE = 'AUTH_SIGNUP_FAILURE',

  CODE_REQUEST = 'AUTH_CODE_REQUEST',
  CONFIRM_CODE_SUCCESS = 'AUTH_CONFIRM_CODE_SUCCESS',
  CONFIRM_CODE_FAILURE = 'AUTH_CONFIRM_CODE_FAILURE',

  LOGOUT = 'AUTH_LOGOUT',

  FORCE_CHANGE_PASSWORD_REQUEST = 'AUTH_FORCE_CHANGE_PASSWORD_REQUEST',
  FORCE_CHANGE_PASSWORD_SUCCESS = 'AUTH_FORCE_CHANGE_PASSWORD_SUCCESS',
  FORCE_CHANGE_PASSWORD_FAILURE = 'AUTH_FORCE_CHANGE_PASSWORD_FAILURE',

  RESEND_CODE_REQUEST = 'AUTH_RESEND_CODE_REQUEST',
  RESEND_CODE_SUCCESS = 'AUTH_RESEND_CODE_SUCCESS',
  RESEND_CODE_FAILURE = 'AUTH_RESEND_CODE_FAILURE',

  CHANGE_PASSWORD_REQUEST = 'AUTH_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE'
}
