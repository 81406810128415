import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AuthActions } from '../store/actions';
import { PagePropsWithDispatch, AppState } from '../interfaces';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../components/Casepipeline';
import { isEmpty } from '../helpers';
import { AuthService } from '../services'

interface PageProps extends PagePropsWithDispatch {
  loggingIn: boolean;
}

const LoginPage = (props: PageProps) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const { loggingIn } = props;
  useEffect(() => {
    if (props.location!.state && props.location!.state!.from && props.location!.state!.from!.pathname) {
      const redirectTo = props.location!.state.redirectTo || props.location!.state.from.pathname;
      props.history!.replace(`/login?redirectUrl=${redirectTo}`)
    }
    AuthService.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(e: any) {
      const { name, value } = (e.target) as any as HTMLInputElement;

      switch (name) {
        case 'username':
          setUsername(value);
          break;
        case 'password':
          setPassword(value);
          break;
        default:
          return
      }
  }

  function handleSubmit(e: any) {
      e.preventDefault();

      setSubmitted(true);

      const { dispatch } = props;
      if (username && password) {
          dispatch(AuthActions.login(username, password, props.location!.search.replace('?redirectUrl=', '')));
      }
  }

  return (
    <div>
         <p className="text-center py-2 text-uppercase">Login</p>
         <form name="form" onSubmit={handleSubmit}>
             <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                 <label htmlFor="username">Username</label>
                 <input type="text" className="form-control" name="username" value={username} onChange={handleChange} />
                 {submitted && isEmpty(username) &&
                     <div className="help-block">Username is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                 <label htmlFor="password">Password</label>
                 <input type="password" className="form-control" name="password" value={password} onChange={handleChange} />
                 {submitted && isEmpty(password) &&  <div className="help-block">Password is required</div> }
             </div>
             <p className="pb-2 text-left m-0">
                 No account yet? <Link to="/signup">Sign up</Link>
             </p>
             <div className="form-group d-flex pt-3">
                 <button className="btn btn-primary btn-block" 
                 disabled={loggingIn}>
                    Login {loggingIn && <LoadingSpinner />}
                 </button>
             </div>
         </form>
     </div>
  );
}

function mapStateToProps(state: AppState) {
    const { loggingIn } = state.auth;
    return {
        loggingIn
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as Login };
export default connectedLoginPage;
