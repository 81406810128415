import APP_CONFIG from '../config';
import { authHeader, history } from '../helpers';
import { CourtData, SaveFilter, MailTemplate, Delivery, DeliveryWithMailDetails } from '../interfaces';
import { SearchOptions, SearchMeta } from '../store/reducers/search';

import axios, { AxiosResponse } from "axios";
import moment from 'moment';
import qs from 'qs';
import { AuthService } from '../services';

const AXIOS = axios.create({
  baseURL: APP_CONFIG.apiUrl,
  responseType: "json"
});

AXIOS.interceptors.request.use(async function (config) {
  // Do something before request is sent
  config.headers = { ...config.headers, ...(await authHeader()) };
  config.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
AXIOS.interceptors.response.use(function (response: AxiosResponse<any>) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data ? response.data : response;
}, function (error: { response: AxiosResponse; status?: any }) {
  // Modify request here
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (process.env.NODE_ENV !== 'production') {
     console.log('An Error Occured >>> ', (error.response || {}).data || error);
  }
 
  // show toasts here ?? 
  if ([401, 403].includes(((error.response || {} as any).status) || error.status)) {
    history.push('/login');
    AuthService.logout();
  }
  return Promise.reject((error.response || {}).data || error);
});

interface ApiResponse<T = any> {
  message: string;
  data: T;
}

interface GenApiResponse <T> extends ApiResponse {
  data: T;
}

interface GetCourtsResponse extends ApiResponse {
  data: {[key: string]: CourtData[]}[]
}

interface GetFiltersResponse extends ApiResponse {
  data: Array<SaveFilter>;
}

interface GetTemplatesResponse extends ApiResponse {
  data: Array<MailTemplate>
}

interface GetDeliveryRecipientCountResponse extends ApiResponse {
  data: {
    Total: number
  }
}

interface GetDeliveriesResponse extends ApiResponse {
  data: Delivery[];
}

interface GetDeliveryResponse extends ApiResponse {
  data: DeliveryWithMailDetails;
}


// TODO (oneeyedsunday) 404 page

class DataService {
  static async getCourts(): Promise<GetCourtsResponse> {
    return AXIOS.get('courts');
}

  static async getFilters(): Promise<GetFiltersResponse> {
    return AXIOS.get('filters');
  }

  static async deleteFilter(filterId: number) {
    return AXIOS.delete(`filters/${filterId}`);
  }

  static getFilterDetails(filterId: number): Promise<GenApiResponse<{ filter: any, executions: any[] }>> {
    return AXIOS.get(`filters/${filterId}`);
  }

  static searchCases(searchParams: SearchOptions, searchMeta: SearchMeta, offset?: number) {
    if (!offset) {
      offset = 0;
    }
    const { courts, ...rest } = searchParams;
    const params: {[key: string]: string | number | number[]} = {
      offset, courts: []
    };
    Object.keys(rest).forEach(key => params[key] = (rest as Record<string, any>)[key]);
    Object.keys(searchMeta).forEach(key => params[key] = (searchMeta as any as Record<string, string>)[key]);
    (courts || []).forEach((courtId: number) => (params.courts as number[]).push(courtId));

    return AXIOS.get('runfilter', { params });
  }

  static executeFilter(filterId: number, searchParams: SearchOptions) {
    const params = {
      startDate: searchParams.startDate,
      endDate: searchParams.endDate
    };

    return AXIOS.get(`filters/${filterId}/execute`, { params });
  }

  static callExportSearch(searchParams: SearchOptions): Promise<Blob> {
    const { courts, ...rest } = {
      ...searchParams,
      startDate: moment(searchParams!.startDate || '').format('YYYY-MM-DD'),
      endDate: moment(searchParams!.endDate || '').format('YYYY-MM-DD')
    };
    const params: any = {
      courts: []
    };
    Object.keys(rest).forEach(key => params[key] = (rest as Record<string, any>)[key]);
    (courts || []).forEach((courtId: number) => (params.courts as number[]).push(courtId));

    return AXIOS.get('export', {
      responseType: 'blob',
      params,
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }

  static getRecipientCount(params: Record<string, string | number | number[]>): Promise<GetDeliveryRecipientCountResponse> {
    return AXIOS.get('delivery/create', {
      params
    });
  }

  static async getMailTemplates(): Promise<GetTemplatesResponse> {
    return AXIOS.get('templates');
  }

  static async createMailDelivery(templateId: number, searchParams: Record<string, number | string | number[]>) {
    return AXIOS.post('delivery', {
      templateId, ...searchParams
    });
  }

  static async getDeliveries(): Promise<GetDeliveriesResponse> {
    return AXIOS.get('delivery');
  }

  static async getDelivery(deliveryId: string): Promise<GetDeliveryResponse> {
    return AXIOS.get(`delivery/${deliveryId}`)
  }

  static async cancelDelivery(deliveryId: string | number): Promise<ApiResponse<Delivery>> {
    return AXIOS.patch(`delivery/${deliveryId}`);
  }

  static async payForDelivery(deliveryId: string | number): Promise<ApiResponse<Delivery>> {
    return AXIOS.patch(`delivery/${deliveryId}/pay`);
  }

  static async scheduleDelivery(deliveryId: string | number): Promise<ApiResponse<Delivery>> {
    return AXIOS.patch(`delivery/${deliveryId}/schedule`);
  }
}

export default DataService;
