import { SEARCH } from '../constants';
import { SITES, CUSTOMERTYPES } from '@betaquick/casepipeline-constants';

export interface SearchOptions {
  courts: Array<number>;
  caseStatus: string;
  site: SITES;
  customerType: CUSTOMERTYPES;
  startDate: string;
  endDate: string;
  offset?: number;
  caseTypes?: string[];
}

export interface SearchMeta {
  saveQuery: boolean;
  filterName?: string;
}

export interface State {
  params?: SearchOptions;
  meta?: SearchMeta;
};

const initialState: State = {
  meta: {
    saveQuery: false
  },
  params: {
    site: SITES.Civil,
    courts: [],
    caseStatus: 'Active',
    customerType: CUSTOMERTYPES.DEFENDANT,
    startDate: '',
    endDate: '',
    caseTypes: []
  }
}

export function reducer(state = initialState, action: { type: SEARCH; meta: SearchMeta, params: SearchOptions }): State {
  switch (action.type) {
    case SEARCH.INITIATE_SEARCH:
      return {
        meta: action.meta,
        params: action.params
      }
    case SEARCH.CLEAR_SEARCH:
      return initialState;
    case SEARCH.CLEAR_META:
      return {
        ...state,
        meta: initialState.meta
      }
    default:
      return state;
  }
}

