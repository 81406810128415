import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AuthActions } from '../store/actions';
import { PagePropsWithDispatch, AppState } from '../interfaces';
import { Link } from 'react-router-dom';
import { STATES as states } from '@betaquick/casepipeline-constants';
import { LoadingSpinner } from '../components/Casepipeline';
import { isEmpty } from '../helpers';
import { AuthService } from '../services';

interface PageProps extends PagePropsWithDispatch {
  needsCode: boolean;
  signingUp: boolean
}

const STATES: Array<{value: string; text: string}> = states.map(({name, abbr}) => ({ text: name, value: abbr }));

const SignUpPage = (props: PageProps) => {

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [zip_code, setZip_code] = useState('');
  const [company, setCompany] = useState('');
  const [state, setState] = useState('');

  const [canResendCode, setCanResendCode] = useState(true);
  
  const [code, setCode] = useState('');

  const { needsCode, signingUp } = props;
  useEffect(() => {
    AuthService.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubmitted(false);
  }, [needsCode]);

  function handleChange(e: any) {
      const { name, value } = (e.target) as any as HTMLInputElement;

      switch (name) {
        case 'name':
          setName(value);
          break;
        case 'email':
          setEmail(value);
          break;
        case 'phone_number':
          setPhone_number(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'confirmPassword':
          setConfirmPassword(value);
          break;
        case 'zip_code':
          setZip_code(value);
          break;
        case 'company':
          setCompany(value);
          break;
        case 'state':
          setState(value);
          break;
        case 'code':
          setCode(value);
          break;
        default:
          return
      }
  }

  function hasAllRequiredAttrs(): boolean {
    return [name, password, confirmPassword, zip_code, email, phone_number, company, state]
      .every(item => !!(item || '').trim());
  }

  function handleSubmit(e: any) {
      e.preventDefault();

      setSubmitted(true);

      const { dispatch } = props;
      if (hasAllRequiredAttrs() && passwordMatch()) {
          dispatch(AuthActions.signUp({ name, email, password, phone_number, zip_code, company, state }));
      }
  }

  function handleCodeSubmission(e: any) {
    e.preventDefault();

    setSubmitted(true);
    initiateCountDown(300); // five minutes
    const { dispatch } = props;
      if (code && email) {
          dispatch(AuthActions.confirmSignUpCode(email, code));
      }
  }

  function resendAuthCode() {
    initiateCountDown(300); // five minutes
    const { dispatch } = props;
    dispatch(AuthActions.resendAuthCode(email));
  }

  /**
   * 
   * @param timeoutSecs time to wait (in seconds) till we can show resend button  
   */
  function initiateCountDown(timeoutSecs: number): void {
    setCanResendCode(false);
    setTimeout(() => {
      setCanResendCode(true);
    }, timeoutSecs * 1000);
  }

  function passwordMatch(): boolean {
    return (password === confirmPassword);
  }

  return (
    <div>
         <p className="text-center py-2 text-uppercase">Sign Up { needsCode ? ': Confirmation' : '' }</p>
         {
           needsCode ? 
           <form name="form" onSubmit={handleCodeSubmission}>
           <div className={'form-group' + (submitted && !code ? ' has-error' : '')}>
               <label htmlFor="code" className="required__field">Authorization Code</label>
               <input type="text" className="form-control" name="code" value={code} onChange={handleChange} />
               {submitted && !code &&
                   <div className="help-block">Authorization Code is required</div>
               }
           </div>
           {
            !signingUp &&  
             <p className="text-left m-0">
              Didnt get Code? 
              <button className="ml-2 btn rounded bg-green py-0" 
              disabled={!canResendCode}
              onClick={resendAuthCode}
              type="button"> Resend</button>
             </p>
           }
          
           <div className="form-group d-flex pt-3">
               <button className="btn btn-primary btn-block" 
               type="submit"
               disabled={signingUp}
               >Confirm Code</button>
               {signingUp &&  <LoadingSpinner />}
           </div>
       </form>
           :
         <form name="form" onSubmit={handleSubmit}>
             <div className={'form-group' + (submitted && isEmpty(name) ? ' has-error' : '')}>
                 <label htmlFor="name" className="required__field">Full Name</label>
                 <input type="text" className="form-control" required name="name" value={name} onChange={handleChange} />
                 {submitted && isEmpty(name) &&
                     <div className="help-block">Name is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(email) ? ' has-error' : '')}>
                 <label htmlFor="email" className="required__field">Email Address</label>
                 <input type="email" className="form-control" required name="email" value={email} onChange={handleChange} />
                 {submitted && isEmpty(email) &&
                     <div className="help-block">Email is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(phone_number) ? ' has-error' : '')}>
                 <label htmlFor="phone_number" className="required__field">Phone Number</label>
                 <input type="tel" className="form-control" required name="phone_number" value={phone_number} onChange={handleChange} />
                 {submitted && isEmpty(phone_number) &&
                     <div className="help-block">Phone Number is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(company) ? ' has-error' : '')}>
                 <label htmlFor="company" className="required__field">Company</label>
                 <input className="form-control" required maxLength={256} name="company" value={company} onChange={handleChange} />
                 {submitted && isEmpty(company) &&
                     <div className="help-block">Company Name is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(state) ? ' has-error' : '')}>
                 <label htmlFor="state" className="required__field">State</label>
                 <select defaultValue="" className="custom-select custom-select-sm" name="state" onChange={handleChange}>
                      <option value="">--Please select a state--</option>
                      {
                        STATES.map(({ text, value }, index) => <option defaultValue={value} value={value} key={index}>{text}</option>)
                      }
                  </select>
                 {submitted && isEmpty(state) &&
                     <div className="help-block">State is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(zip_code) ? ' has-error' : '')}>
                 <label htmlFor="zip_code" className="required__field">Zip Code</label>
                 <input type="tel" className="form-control" maxLength={20} required name="zip_code" value={zip_code} onChange={handleChange} />
                 {submitted && isEmpty(zip_code) &&
                     <div className="help-block">Zip Code is required</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(password) ? ' has-error' : '')}>
                 <label htmlFor="password" className="required__field">Password</label>
                 <input type="password" className="form-control" required name="password" value={password} onChange={handleChange} />
                 {submitted && isEmpty(password) &&
                     <div className="help-block">Password is required</div>
                 }
                 {submitted && !passwordMatch() &&
                     <div className="help-block">Passwords must match</div>
                 }
             </div>
             <div className={'form-group' + (submitted && isEmpty(confirmPassword) ? ' has-error' : '')}>
                 <label htmlFor="confirmPassword" className="required__field">Confirm Password</label>
                 <input type="password" className="form-control" required name="confirmPassword" value={confirmPassword} onChange={handleChange} />
                 {submitted && isEmpty(confirmPassword) &&
                     <div className="help-block">Password is required</div>
                 }
                 {submitted && !passwordMatch() &&
                     <div className="help-block">Passwords must match</div>
                 }
             </div>
             <p className="pb-2 text-left m-0">
                Have an account? <Link to="/login">Sign in</Link>
             </p>
             <div className="form-group d-flex pt-3">
                 <button className="btn btn-primary btn-block" 
                 type="submit"
                 disabled={signingUp}>Signup</button>
                 {signingUp && <LoadingSpinner /> }
             </div>
         </form>
         }
    </div>
  );
}

function mapStateToProps(state: AppState) {
  const { needsCode, signingUp } = state.auth;
    return {
      needsCode, signingUp
    };
}

const connectedSignupPage = connect(mapStateToProps)(SignUpPage);
export { connectedSignupPage as Signup };
export default connectedSignupPage;
