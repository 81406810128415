import React, { useRef, useEffect, createRef, forwardRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { Document, Page, pdfjs } from 'react-pdf';
import { CardHeader, Alert } from 'reactstrap';
import styled from 'styled-components';

const ThemedCardHeader = styled(CardHeader)`
  background-color: #23b7e5 !important;
  color: #fff
`

interface FormLabelProps {
  required?: boolean;
  classNames?: string
}

interface RadioItem {
  label: string;
  value: string;
}

interface RadioItemWithFilters extends RadioItem {
  filters: string[];
}

interface InlineRadioProps {
  handleCaseTypeSelection: (list: string[]) => void;
  defaultSelectedFilters: string[];
  options: RadioItemWithFilters[];
  name: string;
  handler: (e: any) => void;
  value: string
}

const FormLabel = styled.label.attrs((props: FormLabelProps) => ({
  className: `col-xl-3 col-form-label ${props.required ? 'required__field': ''} ${props.classNames}`
}))`
  &::after {
    content: ':'
  }
  @media (min-width: 1200px) {
    text-align: right;
  }
`

interface MultiChecker {
  searchBox: React.RefObject<HTMLInputElement>;
  getSelectedItems: () => any[];
  getSelectedItemsCount: () => number;
  resetSelectedValues: () => void
}

const InlineRadioGroup = forwardRef<any, InlineRadioProps>(({ options, name, handler, value,
    handleCaseTypeSelection, defaultSelectedFilters }, ref) => {
  const msRef = useRef<Array<{ current: MultiChecker }>>(options.map((_, i) => createRef()) as any[]);

  const selectedIndex = options.findIndex(o => o.value === value);

  useEffect(() => {
    if (msRef!.current[selectedIndex]) {
      msRef.current[selectedIndex]!.current!.searchBox.current!.placeholder = generatePlaceholderText(msRef!.current[selectedIndex]!.current!.getSelectedItems());
      msRef!.current[selectedIndex]!.current!.searchBox!.current!.setAttribute('readonly', 'true');
    }
  }, [selectedIndex]);

  function generatePlaceholderText(list: any[]) {
    const count = (list || []).length;
    if (count) {
      return `${count} selected`;
    }
    return 'Narrow Search';
  }

  function handleSelection(list: string[], item: string, index: number) {
    if (msRef && msRef.current && msRef!.current[index]!.current!.searchBox && msRef.current[index].current!.searchBox!.current) {
      msRef.current[index]!.current!.searchBox.current!.placeholder = generatePlaceholderText(list);
    }

    handleCaseTypeSelection(list);
  }
  return (
    <>
    {
      options.map((o, index) => {
        return (
          <div className="form-check" 
          style={{margin: '0.8em 0'}}
          key={`${o.value}-${index}`}>
            <input className="form-check-input" id={`${o.value}-${index}`} 
            type="radio" name={name} value={o.value} defaultChecked={o.value === value}
            onChange={handler} />
            <label className="form-check-label"
            style={{marginRight: '4em', verticalAlign: 'text-bottom'}}
             html-for={`${o.value}-${index}`}>
              { o.label }
            </label>
              {
                <Multiselect
                  ref={msRef.current[index]}
                  avoidHighlightFirstOption={true}
                  options={o.filters || []}
                  selectedValues={defaultSelectedFilters}
                  onSelect={(list: any[], item: string) => handleSelection(list, item, index)}
                  onRemove={(list: any[], item: string) => handleSelection(list, item, index)}
                  placeholder="Narrow Search"
                  isObject={false}
                  isSearchable={false}
                  closeOnSelect={false}
                  style={ { 'chips': { 'display': 'none' }, 'multiselectContainer': { 'width': '15em', 'maxWidth': '15em', 'display': o.value === value ? 'inline-block' : 'none' }, 'searchBox': { 'padding': '0 5px' } } }
                  showCheckbox={true}
                  />
              }
          </div>
        )
       }
      )
    }
    </>
  )
});

const PaginationSpan = styled.span`
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5d9cec;
  background-color: #fff;
  border: 1px solid #dee2e6
`;

interface CDivProps {
  children?: JSX.Element;
  style?: React.CSSProperties
}

const CenteredDiv = (props: CDivProps) => (
  <div className="align-items-center justify-content-center d-flex" style={props.style}>
    { props.children }
  </div>
)

const Spinner = (props: { marginLeft?: any; fontSize?: any }) => (
  <span style={{marginLeft: `${props.marginLeft || '2em'}`, fontSize: props.fontSize}}>
      <i className="fa fa-spin fa-spinner"></i>
  </span>
)

interface PdfViewerProps {
  url: string;
  containerRef: React.MutableRefObject<HTMLElement>
}

const PDFViewerLoadingComponent = 
    <CenteredDiv style={{minHeight: '40vh'}}>
      <>
      <span className="mr-2" style={{fontSize: "2em"}}>Loading Preview</span>
      <Spinner marginLeft="0em" fontSize="2em" />
      </>
    </CenteredDiv>

const PDFViewerErrorComponent = <CenteredDiv style={{minHeight: '40vh'}}>
  <>
    <Alert color="warning">Failed to Load preview</Alert>
  </>
</CenteredDiv>

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const pdfOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const PdfViewer = ({ url, containerRef }: PdfViewerProps) => {
  const [maxPages, setMaxPages] = useState(1);
  const [pageNumber, setPagNum] = useState(1);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);


  useEffect(() => {
    setPageWidth(containerRef.current.clientWidth * 0.8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setPageWidth(containerRef.current.clientWidth * 0.8);
    }, 1000);
    

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, containerRef.current!.clientWidth]);

  function debounce(fn: Function, ms: number) {
    let timer: number | null;
    return () => {
      clearTimeout(timer as number)
      timer = setTimeout(_ => {
        timer = null;
        // @ts-ignore
        fn.apply(this, arguments)
      }, ms)
    };
  }
  

  function onDocumentLoadSuccess({ numPages, ...rest }: pdfjs.PDFDocumentProxy) {
    setMaxPages(numPages);
    setPagNum(1);
  }
  return (
    <div className="d-flex align-items-center justify-content-center">
      {
        pageNumber > 1 && 
        <span className="mx-1" onClick={() => setPagNum(curr => curr - 1)}>
          <i className="fa fa-chevron-circle-left"></i>
        </span>
      }
      
      <Document file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={PDFViewerLoadingComponent}
        error={PDFViewerErrorComponent}
        options={pdfOptions} >
        <Page pageNumber={pageNumber}
        width={pageWidth} />
      </Document>
      {
        pageNumber < maxPages && 
        <span className="mx-1 " onClick={() => setPagNum(curr => curr + 1)}>
          <i className="fa fa-chevron-circle-right"></i>
        </span>
      }
      
    </div>

  )
};
    


export {
    FormLabel, ThemedCardHeader,
    InlineRadioGroup, PaginationSpan,
    Spinner as LoadingSpinner,
    PdfViewer, CenteredDiv
}

export * from './modal';

