import { USER } from '../constants';

const initialState = {
  fetchingUser: false,
  attributes: {},
  updatingBio: false
};


export function reducer(state = initialState, action: { type: string; userAttributes?: any }) {
    switch (action.type) {
        case USER.UPDATE_USER_BIO_REQUEST:
            return {
                ...state,
                updatingBio: true
            }
        case USER.UPDATE_USER_BIO_FAILURE:
            return {
              ...state, updatingBio: false
            }
        case USER.UPDATE_USER_BIO_SUCCESS:
            return {
                ...state,
                updatingBio: false
            }
        case USER.GET_USER_REQUEST:
            return {
              ...state, fetchingUser: true
            }
        case USER.GET_USER_FAILURE:
            return { ...state, fetchingUser: false }
        case USER.GET_USER_SUCCESS:
            return { ...state, fetchingUser: false, attributes: action.userAttributes }
        case USER.CLEAR_USER:
            return initialState;
        default:
            return state
    }
}
