import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { ResponsiveWrapper } from '../Casepipeline/wrappers';


const BlankPage = (props: { children: React.ReactNode }) => {
    return (
    <ContentWrapper noBTop>
        <div className="block-center mt-4 wd-xl d-flex justify-content-center flex-column text-left">
            <ResponsiveWrapper>
                <div className="card-header text-center bg-dark text-white">
                    CasePipeline
                </div>
                <div className="p-2">
                    { props.children }
                </div>
                
            </ResponsiveWrapper>
        </div>
    </ContentWrapper>
    )
};

export default BlankPage;
