import { ALERT } from '../constants';

interface State {
  type: string;
  message: string
}

const initialState: State = {
  type: '',
  message: ''
}

export function reducer(state = initialState, action: { type: ALERT; message: string }): State {
  switch (action.type) {
    case ALERT.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      }
    case ALERT.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      }
    case ALERT.CLEAR:
      return initialState;
    default:
      return state;
  }
}
