import styled from 'styled-components';

const bgStyles = `
    && {
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
    }
   
`

export const ResponsiveWrapper = styled.div.attrs(() => ({
    className: 'card card-flat'
}))`
    @media (min-width: 700px) {
        && {
            width: 450px;
        }
    }
    
    ${bgStyles}
`;


export const ResponsiveWrapperNoBG = styled.div.attrs(() => ({
    className: 'card card-flat'
}))`
    @media (min-width: 700px) {
        && {
            width: 450px;
        }
    }
`;
