import { ProfileOpts } from '../interfaces';
import Auth from '@aws-amplify/auth';
import { AuthService } from './auth';

export class UserService {
  static getCurrentUser() {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  static async updateBio(attrs: Omit<ProfileOpts, 'email'>) {
    let user = await Auth.currentAuthenticatedUser();

    return Auth.updateUserAttributes(user, AuthService.formatCustomAttrs(attrs as any as {[key: string]: string}));
  }
}
