import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type BtnColors = 'primary' | 'secondary' | 'warning' | 'info' | 'danger' | 'success';

export interface CasePModalProps {
  buttonColor: BtnColors;
  buttonLabel: string;
  className?: string;
  text?: string;
  primaryColor: BtnColors;
  primaryCTA: string;
  showSecondaryBtn?: boolean;
  children?: JSX.Element;
  title: string;
  handleAffirmation: Function;
  validationFn?: () => boolean;
};

export const ModalFactory = (props: CasePModalProps): JSX.Element => {
  const {
    buttonLabel, showSecondaryBtn = true,
    buttonColor, validationFn,
    className, text, children, title,
    handleAffirmation, primaryCTA, primaryColor
  } = props;

  const [modal, setModal] = useState(false);

  function handleClick(e: any){
    if (validationFn && !validationFn()) {
      return;
    }
    toggle();
    handleAffirmation(e);
  }

  const toggle = () => setModal(!modal);
  return (
    <div>
      <Button color={buttonColor} onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
         {text}
         {children}
        </ModalBody>
        <ModalFooter>
          <Button color={primaryColor} onClick={handleClick}>{primaryCTA}</Button>{' '}
          {
            showSecondaryBtn && <Button color="secondary" onClick={toggle}>Cancel</Button>
          }
          
        </ModalFooter>
      </Modal>
    </div>
  );
};

